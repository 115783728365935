<script setup lang="ts">
import type { SbSpacerStoryblok } from '~/types/storyblok'

const { blok } = defineProps<{ blok: SbSpacerStoryblok }>()

const classes = computed(() => {
  const classNames = [`shrink-0`]

  if (blok.mobileHeight) {
    classNames.push(`h-${blok.mobileHeight}`)
  }
  if (blok.tabletHeight) {
    classNames.push(`md:h-${blok.tabletHeight}`)
  }
  if (!blok.mobileHeight && !blok.tabletHeight) {
    classNames.push(`h-${blok.height}`)
  }
  else {
    classNames.push(`lg:h-${blok.height}`)
  }

  return classNames
})
</script>

<template>
  <div
    v-editable="blok"
    class="shrink-0"
    :class="classes"
  />
</template>
